import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Col, Card, CardBody, Container, Row, Button } from "reactstrap";
import { BrowserRouter as Router, Link, useParams } from "react-router-dom";
import api from "../../services/api";
import Swal from "sweetalert2";

const EditEquipamentoDmae = () => {
  let { id, state } = useParams();

  const [equipmentsLORA, setEquipmentsLORA] = useState([]);
  const [hydrometers, setHydrometers] = useState([]);

  const [hydroId, setHydroId] = useState("");
  const [loraId, setLoraId] = useState("");
  const [location, setLocation] = useState("");
  const [region, setRegion] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [locationType, setLocationType] = useState("");
  const [clock, setClock] = useState("");

  useEffect(() => {
    api.get("/hydrometers").then((response) => {
      let arr = [];
      response.data.map((elem) => {
        arr.push({
          hydroId: elem.OID_HIDROMETRO,
          hydroNumber: elem.NR_HIDROMETRO,
        });
      });
      setHydrometers(arr);
    });
  }, []);

  useEffect(() => {
    api.get("/equipmentslora").then((response) => {
      let arr = [];
      response.data.map((elem) => {
        arr.push({
          loraId: elem.OID_EQUIPAMENTO_LORA,
          loraDesc: elem.DS_DESCRICAO_EQUIPAMENTO,
          loraNumber: elem.NR_EQUIPAMENTO,
        });
      });
      setEquipmentsLORA(arr);
    });
  }, [hydrometers]);

  useEffect(() => {
    api.get(`/equipmentsdmae/${id}`).then((response) => {
      setHydroId(response.data[0].OID_HIDROMETRO);
      setLoraId(response.data[0].OID_EQUIPAMENTO_LORA);
      setLocation(response.data[0].DS_LOCALIDADE);
      setRegion(response.data[0].DS_LOCALIZACAO_REGIAO);
      setLongitude(response.data[0].DS_LOCALIZACAO_LONGITUDE);
      setLatitude(response.data[0].DS_LOCALIZACAO_LATITUDE);
      setLocationType(response.data[0].TP_LOCALIZACAO);
      setClock(response.data[0].DS_RELOJOARIA_INICIAL);
    });
  }, [equipmentsLORA]);

  const onSubmitHandler = (event) => {
    event.preventDefault();
	let body = {
		OID_HIDROMETRO: hydroId,
		OID_EQUIPAMENTO_LORA: loraId,
		DS_LOCALIDADE: location,
		DS_LOCALIZACAO_REGIAO: region,
		DS_LOCALIZACAO_LONGITUDE: longitude,
		DS_LOCALIZACAO_LATITUDE: latitude,
		TP_LOCALIZACAO: locationType,
		DS_RELOJOARIA_INICIAL: clock,
	}
    /* eslint-disable no-mixed-spaces-and-tabs */
    api.put(`/equipmentsdmae/${id}`, body)
    	.then((response) => {
    		Swal.fire({
    			icon: 'success',
    			title: 'Equipamento DMAE atualizado!',
    		});
    	})
    	.catch(function (error) {
    		Swal.fire({
    			icon: 'warning',
				title: 'Falha na Atualização, verifique os dados e tente novamente',
    		});
    	});
  };
    /* eslint-disable no-mixed-spaces-and-tabs */

  const onChangeHandler = (prop) => (event) => {
    if (prop === "hydroId") {
      setHydroId(event.target.value);
    } else if (prop === "loraId") {
      setLoraId(event.target.value);
    } else if (prop === "location") {
      setLocation(event.target.value);
    } else if (prop === "region") {
      setRegion(event.target.value);
    } else if (prop === "longitude") {
      setLongitude(event.target.value);
    } else if (prop === "latitude") {
      setLatitude(event.target.value);
    } else if (prop === "locationType") {
      setLocationType(event.target.value);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
       
          <title>Cadastro - Equipamento DMAE</title>
   
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
               {/* {state ? ( */}
                <Link to={'/calibragem'}> 
                <Button
                  color="link"
                  className="btn btn-lg btn-link waves-effect"
                >
                  <i className="dripicons-arrow-thin-left" /> Voltar
                </Button>
              </Link>
            {/* ) : (
                <Link to="/cadastro/EquipamentoDmae">
                  <Button
                    color="link"
                    className="btn btn-lg btn-link waves-effect"
                  >
                    <i className="dripicons-arrow-thin-left" />
                    Voltar
                  </Button>
                </Link>
                 )} */}
              </Col>
            </Row>
          </div>
        </Container>

        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <form onSubmit={onSubmitHandler}>
                  <Row className="mb-3">
                    <label
                      htmlFor="OID_HIDROMETRO"
                      className="col-md-2 col-form-label"
                    >
                      Hidrômetro
                    </label>
                    <div className="col-md-6">
                      <select
                        name="OID_HIDROMETRO"
                        className="form-control form-control-lg"
                        onChange={onChangeHandler("hydroId")}
                        value={hydroId}
                      >
                        {hydrometers?.map((hydro) => (
                          <option key={hydro.hydroId} value={hydro.hydroId}>
                            {hydro.hydroNumber}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="OID_EQUIPAMENTO_LORA"
                      className="col-md-2 col-form-label"
                    >
                      Equipamento 4G
                    </label>
                    <div className="col-md-6">
                      <select
                        name="OID_EQUIPAMENTO_LORA"
                        className="form-control form-control-lg"
                        onChange={onChangeHandler("loraId")}
                        value={loraId}
                      >
                        {equipmentsLORA?.map((equip) => (
                          
                          <option key={equip.loraId} value={equip.loraId}>
                            {equip.loraNumber}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="DS_LOCALIDADE"
                      className="col-md-2 col-form-label"
                    >
                      Localidade
                    </label>
                    <div className="col-md-6">
                      <input
                        required
                        className="form-control form-control-lg"
                        type="text"
                        name="DS_LOCALIDADE"
                        value={location}
                        onChange={onChangeHandler("location")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="DS_LOCALIZACAO_REGIAO"
                      className="col-md-2 col-form-label"
                    >
                      Região
                    </label>
                    <div className="col-md-6">
                      <select
                        name="DS_LOCALIZACAO_REGIAO"
                        className="form-control form-control-lg"
                        onChange={onChangeHandler("region")}
                      >
                        <option value="NORTE">Norte</option>
                        <option value="LESTE">Leste</option>
                        <option value="OESTE">Oeste</option>
                        <option value="SUL">Sul</option>
                        <option value="CENTRAL">Central</option>
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="DS_LOCALIZACAO_LONGITUDE"
                      className="col-md-2 col-form-label"
                    >
                      Latitute
                    </label>
                    <div className="col-md-6">
                      <input
                        //required
                        className="form-control form-control-lg"
                        type="text"
                        name="DS_LOCALIZACAO_LATITUDE"
                        value={latitude}
                        onChange={onChangeHandler("latitude")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="DS_LOCALIZACAO_LONGITUDE"
                      className="col-md-2 col-form-label"
                    >
                      Longitude
                    </label>
                    <div className="col-md-6">
                      <input
                        //required
                        className="form-control form-control-lg"
                        type="text"
                        name="DS_LOCALIZACAO_LONGITUDE"
                        value={longitude}
                        onChange={onChangeHandler("longitude")}
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <label
                      htmlFor="TP_LOCALIZACAO"
                      className="col-md-2 col-form-label"
                    >
                      Tipo de Localização
                    </label>
                    <div className="col-md-6">
                      <select
                        name="TP_LOCALIZACAO"
                        className="form-control form-control-lg"
                        onChange={onChangeHandler("locationType")}
                        value={locationType}
                      >
                        <option value="F">Final</option>
                        <option value="T">Temporária</option>
                      </select>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="button-items text-center">
                      <Button color="primary" className="btn-lg" type="submit">
                        Atualizar
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default EditEquipamentoDmae;
