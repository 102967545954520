import React from 'react'
import AllRoutes from "./routes/allRoutes";

import "./assets/scss/theme.scss"

const App = () => {
  return (
    <AllRoutes />
  )
}

export default App