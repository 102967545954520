import { Divider } from '@mui/material';
import Button from 'components/shared/Button/Button';
import React from 'react';

const Support = () => {
	return (
		<div className="page-content">
			<label id="component-title">Suporte Técnico</label>
			<div className="container">
				{/* <div className="row" style={{marginBottom: "1rem", marginTop: "1rem"}}>
					<Button text="Chat Online"/>
				</div> */}
				<div className="row">
					<label style={{fontSize: "20px"}}>Telefone: </label>
					<Divider
						style={{ borderColor: 'purple', border: 'solid' }}
					/>
				</div>
				<div className="row">
					<label style={{fontSize: "20px"}}>WhatsApp: </label>
					<Divider
						style={{ borderColor: 'purple', border: 'solid' }}
					/>
				</div>
				<div className="row">
					<label style={{fontSize: "20px"}}>Email: </label>
					<Divider
						style={{ borderColor: 'purple', border: 'solid' }}
					/>
				</div>
			</div>
		</div>
	);
};

export default Support;
