import React, { useState } from 'react';
import propTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import DashboardIcon from '@mui/icons-material/Dashboard';
import BuildIcon from '@mui/icons-material/Build';
import SpeedIcon from '@mui/icons-material/Speed';
import SettingsIcon from '@mui/icons-material/Settings';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HelpIcon from '@mui/icons-material/Help';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import BusinessIcon from '@mui/icons-material/Business';

import { Collapse } from '@mui/material';

import './Sidebar.scss';

import "../../assets/scss/custom/global.css";

import AuthService from "../../services/Auth.service";
const user = AuthService.getCurrentUser();




// console.log(user.empresa[0])
// console.log(user.empresa[0].OID_EMPRESA)

let checkempresa = null;
let DS_STATUS = null;
if(user != null){
	
	DS_STATUS = user.user.DS_STATUS
 if(user.empresa[0] != undefined){
  checkempresa = user.empresa[0].OID_EMPRESA
 

 }
}


const styles = {
	firstSubMenu: {
		fontSize: '15px',
	},
};

const Sidebar = (props) => {
	let navigate = useNavigate();

	const [selectedIndex, setSelectedIndex] = useState('');

	const [openCadastro, setOpenCadastro] = useState(false);
	const [openConfig, setOpenConfig] = useState(false);
	const [openModulo, setOpenModulo] = useState(false);

	const handleClick = (event, url) => {
		setSelectedIndex(url);
		navigate(`/${url}`);
	};

	const openMenu = (event, menu) => {
		if (menu === 'cadastro') {
			setOpenCadastro(!openCadastro);
			setOpenConfig(false);
			setOpenModulo(false);
			return;
		}
		if (menu === 'configuracao') {
			setOpenConfig(!openConfig);
			return;
		}
		if (menu === 'modulo') {
			setOpenModulo(!openModulo);
			return;
		}
	};

	return (
		<React.Fragment>
			<div
				id="sidebar"
				className="vertical-menu"
				style={{ background: '#cef1fc' }}
			>
				<List>

				{DS_STATUS == 1 ?
					<ListItem disablePadding className="sidebarItem">
						<ListItemButton
							selected={selectedIndex === 'identificacao'}
							onClick={(event) => handleClick(event, 'identificacao')}
						>
							<ListItemIcon className="sidebarItem">
								<AssignmentLateIcon />
							</ListItemIcon>
							<ListItemText primary="Identificação" />
						</ListItemButton>
					</ListItem>
					: null}

					{/* {checkempresa != null ?
					<ListItem disablePadding className="sidebarItem">
						<ListItemButton
							selected={selectedIndex === 'moradores'}
							onClick={(event) => handleClick(event, 'moradores')}
						>
							<ListItemIcon className="sidebarItem">
								<SupervisedUserCircleIcon />
							</ListItemIcon>
							<ListItemText primary="Moradores" />
						</ListItemButton>
					</ListItem>
					: null } */}

						{DS_STATUS != 1 ?
					<>
					<ListItem disablePadding className="sidebarItem">
						<ListItemButton
							selected={selectedIndex === 'dashboard'}
							onClick={(event) => handleClick(event, 'dashboard')}
						>
							<ListItemIcon className="sidebarItem">
								<DashboardIcon />
							</ListItemIcon>
							<ListItemText primary="Dashboard" />
						</ListItemButton>
					</ListItem>
					</>
					: null}	

					
					<ListItem disablePadding className="sidebarItem">
						<ListItemButton
							selected={selectedIndex === 'condominios'}
							onClick={(event) => handleClick(event, 'condominios')}
						>
							<ListItemIcon className="sidebarItem">
								<BusinessIcon />
							</ListItemIcon>
							<ListItemText primary="Prefeituras" />
						</ListItemButton>
					</ListItem>
					

				

					{checkempresa != null || DS_STATUS == 10 ?
					<>	

					<ListItem disablePadding className="sidebarItem">
						<ListItemButton
							selected={selectedIndex === 'cadastro'}
							onClick={(event) => openMenu(event, 'cadastro')}
						>
							<ListItemIcon className="sidebarItem">
								<BuildIcon />
							</ListItemIcon>
							<ListItemText primary="Cadastro" />
							{openCadastro ? <ExpandLess /> : <ExpandMore />}
						</ListItemButton>
					</ListItem>

					<Collapse
						in={openCadastro}
						timeout="auto"
						unmountOnExit
						sx={{ ml: 6, fontSize: '15px' }}
					>
						<List component="div" disablePadding>
							<ListItemButton
								sx={styles.firstSubMenu}
								selected={selectedIndex === 'modulo'}
								onClick={(event) => openMenu(event, 'modulo')}
							>
								<ListItemText primary="Módulo" />
								{openModulo ? <ExpandLess /> : <ExpandMore />}
							</ListItemButton>

							<Collapse
								in={openModulo}
								timeout="auto"
								unmountOnExit
								sx={{ ml: 2, fontSize: '15px' }}
							>
								<List component="div" disablePadding>
								{ DS_STATUS == 10 ?
								<>	
									<ListItemButton
										selected={
											selectedIndex ===
											'cadastro/modulo/fabricantelora'
										}
										onClick={(event) =>
											handleClick(
												event,
												'cadastro/modulo/fabricantelora'
											)
										}
									>
										<ListItemText primary="Fabricante 4G" />
									</ListItemButton>

									</>
								: null}				
									<ListItemButton
										selected={
											selectedIndex ===
											'cadastro/modulo/equipamentolora'
										}
										onClick={(event) =>
											handleClick(
												event,
												'cadastro/modulo/equipamentolora'
											)
										}
									>
										<ListItemText primary="Modulos 4G" />
									</ListItemButton>
								</List>
							</Collapse>

							<ListItemButton
								selected={
									selectedIndex === 'cadastro/hidrometro'
								}
								onClick={(event) =>
									handleClick(event, 'cadastro/hidrometro')
								}
							>
								<ListItemText primary="Medidores" />
							</ListItemButton>

							<ListItemButton
								selected={
									selectedIndex === 'cadastro/equipamentokit'
								}
								onClick={(event) =>
									handleClick(
										event,
										'cadastro/equipamentokit'
									)
								}
							>
								<ListItemText primary="Equipamento KIT" />
							</ListItemButton>
						</List>
					</Collapse>

					</>
					: null}	



				{checkempresa != null || DS_STATUS == 10 || DS_STATUS == 9 ?
					<>	
					<ListItemButton
						sx={styles.firstSubMenu}
						selected={selectedIndex === 'configuracao'}
						onClick={(event) => openMenu(event, 'configuracao')}
					>
						<ListItemIcon className="sidebarItem">
							<SettingsIcon />
						</ListItemIcon>
						<ListItemText primary="Configuração" />
						{openConfig ? <ExpandLess /> : <ExpandMore />}
					</ListItemButton>

					<Collapse
						in={openConfig}
						timeout="auto"
						unmountOnExit
						sx={{ ml: 6, fontSize: '15px' }}
					>
						<List component="div" disablePadding>

						{ DS_STATUS == 10  ?
							<ListItemButton
								selected={
									selectedIndex ===
									'cadastro/configuracao/payload'
								}
								onClick={(event) =>
									handleClick(
										event,
										'cadastro/configuracao/payload'
									)
								}
							>
								<ListItemText primary="Payloads" />
							</ListItemButton>
							: null}	

							<ListItemButton
								selected={selectedIndex === 'calibragem'}
								onClick={(event) =>
									handleClick(event, 'calibragem')
								}
							>
								<ListItemText primary="Calibração" />
							</ListItemButton>

							{ DS_STATUS == 10  ?
							<ListItemButton
								selected={
									selectedIndex ===
									'cadastro/configuracao/acoesequipamentos'
								}
								onClick={(event) =>
									handleClick(
										event,
										'cadastro/configuracao/acoesequipamentos'
									)
								}
							>
								<ListItemText primary="Ações Equipamentos" />
							</ListItemButton>
							: null}	
						</List>
					</Collapse>

					</>
					: null}	

					{DS_STATUS == null || DS_STATUS == 10  ?
					<>				

					<ListItem disablePadding className="sidebarItem">
						<ListItemButton
							selected={selectedIndex === 'consumo'}
							onClick={(event) => handleClick(event, 'consumo')}
						>
							<ListItemIcon>
								<SpeedIcon />
							</ListItemIcon>
							<ListItemText primary="Consumo" />
						</ListItemButton>
					</ListItem>

					</>
					: null}	

					{checkempresa != null || DS_STATUS == 10 ?
					<>	

					

					<ListItem disablePadding className="sidebarItem">
						<ListItemButton
							selected={selectedIndex === 'suporte'}
							onClick={(event) => handleClick(event, 'suporte')}
						>
							<ListItemIcon className="sidebarItem">
								<SupportAgentIcon />
							</ListItemIcon>
							<ListItemText primary="Suporte Técnico" />
						</ListItemButton>
					</ListItem>
					</>
					: null}	

					
				</List>
			</div>
		</React.Fragment>
	);
};

export default Sidebar;

Sidebar.propTypes = {
	open: propTypes.bool,
};
