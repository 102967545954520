import React, { useRef, useState , useEffect  } from "react";

//  import Button from "components/shared/Button/Button";
import Input from "components/shared/Inputs/Inputs";
import SearchTable from "components/shared/SearchTable/SearchTable";

import api from "../../services/api";
import Swal from "sweetalert2";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Col, Card, CardBody, Container, Row, Button } from 'reactstrap';

import VerMorador from './VerMorador';
import Reenviar from './Reenviar';
import AuthService from "../../services/Auth.service";
import PersonAddIcon  from '@mui/icons-material/PersonAdd';
import DomainAddIcon  from '@mui/icons-material/DomainAdd';

import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';

const CondominiosImoveis = () => {


  let { id } = useParams();
  const initialState = {
    number: "",
    desc: "",
    model: "",
    status: "",
  };

  const [EmpresaId ] = useState(id);



  const user = AuthService.getCurrentUser();

  let checkempresa = null;
  let razao = null;
  if(user != null){
  if(user.empresa[0] != undefined){
    checkempresa = user.empresa[0].OID_EMPRESA
    razao = user.empresa[0].NM_RAZAO_SOCIAL;
  }
  }

  const [showModal, setShowModal] = useState(false);

  const modalRef = useRef();

  const openModal = () => {
 
    setShowModal(true);
  };

  const [employees, setEmployees] = useState([]);
  const handleShow = e => {
   
      setShowModal(true);
    
  };

//   function updateEmployee(id, newName, newRole) {
//     const updatedEmployees = employees.map((employee) => {
//         if (id == employee.id) {
//             return { ...employee, name: newName, role: newRole };
//         }

//         return employee;
//     });
//     setEmployees(updatedEmployees);
// }
 
  const [search, setSearch] = useState(initialState);
  const [values, setValues] = useState([]);
  const [showList, setShowList] = useState(false);
  const [data, setData] = useState([])

  useEffect(() => {
		api.get('/imovel/'+EmpresaId).then((response) => {
			let arr = [];
			arr = formatArray(response.data);
			setData(arr)
			setValues(arr);
		});
	}, []);


  const onChangeHandler = (prop) => (event) => {
    setSearch({ ...search, [prop]: event.target.value });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (!search.number && !search.desc && !search.model && !search.status) {
      Swal.fire({
        icon: "warning",
        text: "Preencha ao menos um campo para fazer uma pesquisa",
      });
      return;
    }

    if (!search.number && (search.desc || search.model) && !search.status) {
      Swal.fire({
        icon: "warning",
        text: "Preencha o campo Status para fazer uma pesquisa sem Número Hidrômetro",
      });
      return;
    }

    let body = {
      NR_HIDROMETRO: search.number,
      DS_DESCRICAO_HIDROMETRO: search.desc,
      DS_MODELO_HIDROMETRO: search.model,
      TP_ATIVO: search.status,
    };

    api
      .post("/hydrometers/filter", body)
      .then((response) => {
        let arr = formatArray(response.data);
        if (arr.length === 0) {
          Swal.fire({
            icon: "warning",
            text: "Nenhum resultado encontrado, refaça a sua busca!",
          });
          setShowList(false);
        } else {
          Swal.fire({
            icon: "success",
            timer: 500,
          });
          arr.map((elem) => {
            if (elem.status === "S") {
              elem.status = "Ativo";
            }
            if (elem.status === "N") {
              elem.status = "Inativo";
            }
          });

          console.log(arr);
          setValues(arr);
          setSearch(initialState);
          setShowList(true);
        }
      })
      .catch(() => {
        Swal.fire({
          icon: "warning",
          text: "Erro Tente Novamente!",
        });
        setShowList(false);
      });
  };

  const formatArray = (data) => {
    let arr = [];
    data.map((elem) => {
      arr.push({
        imovel: elem.imovel,
        OID_IMOVEL: elem.OID_IMOVEL,
        nome: elem.DS_NOME,
        sobrenome: elem.DS_SOBRENOME,
        email: elem.DS_EMAIL,
        telefone: elem.NR_TELEFONE,
        status: elem.DS_STATUS,
        id: elem.OID_USUARIO,
      });
    });
    return arr;
  };
  
  return (
    
    <React.Fragment>
      
      <div className="page-content">
        
        <label id="component-title">Condomínios | {razao}</label>


        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>


               


              
                    <Link to={`/condominios/`}>
                    <Button
                    // onClick={() => navigate(-1)}
                      color="link"
                      className="btn btn-lg btn-link waves-effect"
                    >
                      <i className="dripicons-arrow-thin-left" /> voltar 
                    </Button>
                    </Link>
                

            
              </Col>
            </Row>
          </div>
        
        </Container>
     
        {/* <form className="component-form container" style={{ margin: "0px" }}>
          <div className="container">
            <div className="row" style={{ textAlign: "end" }}>
              
              <div className="col-6" style={{ alignSelf: "center" }}>
              <Link to="/cadastro/imovel-cadastro">
                <Button
                color="info"
                className="btn-lg btn-dark"
               
                  
                  
                >Cadastrar Condomínio <DomainAddIcon/></Button>
                </Link>
              </div>
            </div>
           
            
           
            
          </div>
        </form> */}
        {/* <SearchTable
          tableHead={[
            "Nome",
            
            "",
          ]}
          tableBody={[values, 1]}
          showTable={showList}
        >
          {values?.map((maker, key) => (
            <td key={key}>
              <Link
                type="button"
                to={`/cadastro/hidrometro-update/${maker.id}`}
                color="link"
                size="sm"
                className="btn-light waves-effect waves-light"
              >
                <i className="dripicons-document-edit" />
              </Link>
            </td>
          ))}
        </SearchTable> */}
        <div className="table-responsive">
						<table className="table align-middle table-nowrap mb-0">
							<thead className="table-light">
								<tr>
                  
									<th className="align-middle">
                  Condomínio
									</th>
									<th className="align-middle">Síndico</th>
                  <th className="align-middle">Tel.</th>
                 
									<th className="align-middle">STATUS</th>
									<th className="align-middle"></th>
								</tr>
							</thead>
							<tbody>
                {values.length >0 ? 
								values?.map((arr, key) => (
									<tr key={'_tr_' + key}>
                    
										<td>{arr.imovel} </td>
										<td>
                    {arr.nome == null ? (

                      <span> Aguardando Cadastro </span>
                      // <Link 
                     
                      // to={`/cadastro/moradores-cadastro/${arr.OID_IMOVEL}`}
                      // >
                      // <Button
                      // color="info"
                      // className="btn-lg btn-primary"

                      // >Cadastrar   <PersonAddIcon/></Button>
                      // </Link>
												//  <span
                        //  className="reenviar"> <br></br> Adicionar   <PersonAddIcon/>
                        //              </span>
											) : (
                        <span>
												{arr.nome} {arr.sobrenome}
												</span>
											)}
                      
                      
                    
                      </td>
                      <td>
                      <span> Aguardando Cadastro </span>
                       </td>
                   
										<td>
                      {console.log(arr.status)}
                      {console.log(arr.nome)}
                      {
                      (() => {
                        if(arr.status == null && arr.nome != null){
                          return  <span className="badge bg-success">
													Cadastro Completo
												</span>
                        }
                        if(arr.status == 1 && arr.nome != null){
                          return <span className="badge bg-info bg-primary">
													E-mail enviado
												</span>  
                        }
                        if(arr.status == null && arr.nome == null){
                         return <span className="badge bg-info bg-warning">
													Aguardando Cadastro
												</span>  
                        }
                      })()
                    }
                                            
											
                      {arr.status == 1 ? (
                        /* eslint-disable no-mixed-spaces-and-tabs */
												  <Reenviar
                          id={arr.OID_IMOVEL}
                          name={arr.nome}
                          role={arr.role}                          
                      />           
                      /* eslint-disable no-mixed-spaces-and-tabs */        
											) : null }

										</td>
										<td>				
                      <VerMorador
                                    id={arr.OID_IMOVEL}
                                    name={arr.nome}
                                    role={arr.role}
                                    
                                />
										</td>
									</tr>
								)) : <tr><td>Nenhum Registro, Cadastre um Morador </td></tr>}
							</tbody>
						</table>
					</div>
          
      </div>
     
      
    </React.Fragment>
  );
};

export default CondominiosImoveis;
