import React, { useState, useEffect } from "react";

import AuthService from "../../services/Auth.service";
import BarChart from "../AllCharts/chartjs/barchart";
import { Row, Col, Card, CardBody, CardTitle, Container } from "reactstrap";

const Dashboard = () => {

  const user = AuthService.getCurrentUser();

  let checkempresa = null;
  let razao = null;
  if(user != null){
  if(user.empresa[0] != undefined){
    checkempresa = user.empresa[0].OID_EMPRESA
    razao = user.empresa[0].NM_RAZAO_SOCIAL;
  }
  }
  
  console.log(user)
  return (
    <React.Fragment>
      <div className="page-content">

      <Container fluid={true}>
          <ol className="breadcrumb m-0"></ol>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">Dashboard</CardTitle>
                  <BarChart />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        
        {/* <div className="container">
          <div className="row">
            <div className="col" style={{textAlign: "center", fontSize: "25px", marginTop: "3.5rem"}}>
              <label>Seja Bem Vindo Ao Sistema Smart@Cities!</label>
            </div>
          </div>
          <div className="row">
            <div className="col" style={{textAlign: "center"}}>
              <label>Sistema de Gerenciamento e consulta de medidores para Condomínios</label>
            </div>
          </div>
        </div> */}
      </div>
    </React.Fragment>
  )
}

export default Dashboard;


