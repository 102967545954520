import React, { useState } from "react";
import propTypes from "prop-types";

import { useNavigate, Link } from "react-router-dom";

import logolightImg from "../../assets/images/logo-light.png";
import logosmImg from "../../assets/images/logo-sm.png";

// import Button from "../../components/shared/Button/Button";
import AuthService from "../../services/Auth.service";

import { Button, Dropdown, DropdownMenu, DropdownToggle, Form, Input } from "reactstrap"
// import { Input } from "reactstrap";

const Header = (props) => {
  const [value, setValue] = useState("");

  const user = AuthService.getCurrentUser();

  let email = null;
  let DS_STATUS = null;
    if(user != null){
     
      DS_STATUS = user.user.DS_STATUS
      email = user.user.DS_EMAIL
    
  }

  const navigate = useNavigate();
  const logOut = (event) => {
    event.preventDefault();

   
    AuthService.logout();
    navigate("/");
    window.location.reload();
    document.location.reload(true);
       
    
    }

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <header id="page-topbar container">
      <div className="navbar-header row">
        <div className="col-2 " style={{textAlign: "center"}}>
          {/* <button
            type="button"
            className="btn btn-sm px-3 font-size-24 header-item waves-effect"
            id="vertical-menu-btn"
            onClick={props.changeOpen}
            data-target="#topnav-menu-content"
          >
            <i className="mdi mdi-menu"></i>
          </button> */}
          {props.open ? (
            <Link to="/dashboard" className="logo">
              <span>
              <span className="image" 
              style={{color: "black",fontWeight:"600",fontSize: 25,marginLeft:"auto",marginright:"auto",textAlign: "center"}}>
                Smart@Cities</span>
                {/* <img src={logolightImg} alt="Logo Syswater" height="40" /> */}
              </span>
            </Link>
          ) : (
            <Link to="/dashboard" className="logo">
              <span>
                <img src={logosmImg} alt="" height="40" />
              </span>
            </Link>
          )}
        </div>
        <div className="col-8">
          <Input
            placeholder="O que você deseja encontrar?"
            value={value}
            onChange={handleChange}
            icon="ti-search"
            styles={{
              border: "0px",
              paddingLeft: "1rem",
              width: "25%",
              marginRight: "auto",
            }}
          />
        </div>
        <div className="col-1" style={{textAlign: "end"}}>
        <div className="flexGrow">
                {email}
            </div>
        </div>
        <div className="col-1" style={{textAlign: "end"}}>
        <div className="flexGrow">
                <button  className='btn btn-link'  onClick={logOut}>Sair</button>
            </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

Header.propTypes = {
  changeOpen: propTypes.func,
  open: propTypes.bool,
  toggleMenuCallback: propTypes.func,
};
